import { Box, Typography, Avatar, Paper, Stack, alpha } from '@mui/material';
import { Shield, Verified } from 'lucide-react';
import creatorImage from './Assets/images/creator.jpg';


const CreatorSection = () => {
  return (
    <Paper
      elevation={0}
      sx={{
        mt: 4,
        p: 4,
        borderRadius: 4,
        border: '1px solid',
        borderColor: 'divider',
        background: (theme) => `linear-gradient(45deg, 
          ${alpha(theme.palette.primary.main, 0.05)}, 
          ${alpha(theme.palette.background.paper, 0.8)}
        )`,
      }}
    >
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        spacing={4}
        alignItems="center"
        justifyContent="center"
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Avatar
            src={creatorImage}
            alt="Creator"
            sx={{
              width: 120,
              height: 120,
              border: 3,
              borderColor: 'primary.main',
            }}
          />
          <Box
            sx={{
              position: 'absolute',
              bottom: -4,
              right: -4,
              bgcolor: 'primary.main',
              borderRadius: '50%',
              p: 1,
              display: 'flex',
            }}
          >
            <Shield size={24} color="white" />
          </Box>
        </Box>

        <Box sx={{ maxWidth: 600, textAlign: { xs: 'center', md: 'left' } }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent={{ xs: 'center', md: 'flex-start' }}
            sx={{ mb: 2 }}
          >
            <Typography variant="h6" fontWeight="bold">
              Born from Necessity, Built with Purpose
            </Typography>
            <Verified size={20} color="primary" />
          </Stack>
          
          <Typography color="text.secondary" sx={{ mb: 2 }}>
            CyberSecNews.AI was born from the real-world challenges faced by cybersecurity professionals.
            Created by security experts who understand your daily workflow, we've designed a solution
            that delivers what matters most: actionable intelligence without the noise.
          </Typography>
          
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'primary.main',
              fontWeight: 500
            }}
          >
            "As cybersecurity expert, I created CyberSecNews.AI to solve a challenge I faced daily: information overload. What started as a personal solution now helps security professionals stay efficiently informed, focusing on what matters most - protecting their organizations." - Mario Bouchard, Founder"
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default CreatorSection;

