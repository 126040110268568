import React, { useState } from 'react';
import CreatorSection from './CreatorSection';
import DashboardPreview from './DashboardPreview';
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Grid,
  Card,
  CardContent,
  Stack,
  Chip,
  Divider,
  useTheme,
  alpha,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  useMediaQuery
} from '@mui/material';
import {
  Zap,
  Shield,
  Bot,
  Clock,
  Target,
  Sun,
  Moon,
  Menu as MenuIcon,
  X,
} from 'lucide-react';
import Footer from './Footer';
import { blue } from '@mui/material/colors';
import ExampleArticle from './ExampleArticle';
import cybersecnewslogo from './Assets/images/cybersecnewslogo.png';

const FeatureCard = ({ icon: Icon, title, description }) => {
  const theme = useTheme();

  return (
    <Card
      elevation={2}
      sx={{
        height: '100%',
        p: 2,
        borderRadius: 4,
        transition: 'all 0.3s',
        mb: { xs: 1, sm: 1 },
        bgcolor: theme.palette.mode === 'dark'
          ? alpha(theme.palette.background.paper, 0.6)
          : theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        m: 0,
        '&:hover': {
          transform: { xs: 'none', sm: 'translateY(-6px)' }, // Désactiver l'animation sur mobile
          boxShadow: theme.shadows[6],
          '& .icon-wrapper': {
            bgcolor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
          },
        },
        position: 'relative',
        zIndex: 1,
        '&:hover': {
          zIndex: 2,
          transform: 'translateY(-6px)',
          boxShadow: theme.shadows[6],
        }
      }}
    >
      <Box
        className="icon-wrapper"
        sx={{
          width: 56,
          height: 56,
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          mb: 2,
          bgcolor: alpha(theme.palette.primary.main, theme.palette.mode === 'dark' ? 0.2 : 0.1),
          color: theme.palette.primary.main,
          transition: 'all 0.3s',
        }}
      >
        <Icon size={28} />
      </Box>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        {title}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {description}
      </Typography>
    </Card>
  );
};

// Composant de menu mobile
const MobileMenu = ({ open, onClose, onLoginClick, onSignUpClick }) => (
  <Drawer anchor="right"
   open={open}
   onClose={onClose}
   >
    <Box sx={{ width: 250, p: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
        <IconButton onClick={onClose}>
          <X size={24} />
        </IconButton>
      </Box>
      <List>
        <ListItemButton onClick={() => { onLoginClick(); onClose(); }}>
          <ListItemText primary="Sign In" />
        </ListItemButton>
        <ListItem>
          <Button
            fullWidth
            variant="contained"
            onClick={() => { onSignUpClick(); onClose(); }}
            startIcon={<Zap size={16} />}
            sx={{ borderRadius: 2 }}
          >
            Start Free Trial
          </Button>
        </ListItem>
      </List>
    </Box>
  </Drawer>
);


// AppBar responsive
const ResponsiveAppBar = ({ onLoginClick, onSignUpClick }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <AppBar
      position="fixed"
      color="inherit"
      elevation={0}
      sx={{
        borderBottom: `1px solid ${theme.palette.divider}`,
        backdropFilter: 'blur(20px)',
        bgcolor: alpha(theme.palette.mode === 'light'
          ? theme.palette.background.default
          : theme.palette.background.paper,
          0.8
        ),
        width: '100%',
      }}
    >
      <Container maxWidth="lg"
        sx={{
          px: { xs: 1, sm: 2, md: 4 }, // Réduire le padding sur mobile
          overflow: 'hidden', // Contenir tout débordement
        }}
      > {/* Ajouter un Container pour contrôler la largeur */}
        <Toolbar
          disableGutters // Retirer les paddings par défaut
          sx={{
            px: { xs: 1, sm: 2, md: 4 },
            minHeight: { xs: '56px', sm: '64px' },
            justifyContent: 'space-between',
            width: '100%',
            position: 'relative', // Assurer le bon positionnement
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            flexShrink: 1,
            minWidth: 0,
          }}>
            <img
              src={cybersecnewslogo}
              alt="CyberSecNews.AI"
              style={{
                width: isMobile ? 20 : 30,
                height: isMobile ? 20 : 30,
                flexShrink: 0,
              }}
            />
            <Typography
              variant={isMobile ? "subtitle1" : "h6"}
              fontWeight="bold"
              sx={{
                ml: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              CyberSecNews.AI
            </Typography>
          </Box>

          {isMobile ? (
            <>
              <IconButton
                onClick={() => setMobileMenuOpen(true)}
                sx={{
                  ml: 1,
                  flexShrink: 0 // Empêche le bouton de se comprimer
                }}
              >
                <MenuIcon size={20} />
              </IconButton>
              <MobileMenu
                open={mobileMenuOpen}
                onClose={() => setMobileMenuOpen(false)}
                onLoginClick={onLoginClick}
                onSignUpClick={onSignUpClick}
              />
            </>
          ) : (
            <Stack direction="row" spacing={2} alignItems="center">
              <Button color="inherit" onClick={onLoginClick}>
                Sign In
              </Button>
              <Button
                variant="contained"
                onClick={onSignUpClick}
                startIcon={<Zap size={16} />}
                sx={{ borderRadius: 2 }}
              >
                Start Free Trial
              </Button>
            </Stack>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};


// Conteneur principal responsive
const ResponsiveContainer = ({ children, maxWidth = "lg", ...props }) => (
  <Container
    maxWidth={maxWidth}
    sx={{
      px: { xs: 2, sm: 3, md: 4 },
      ...props.sx
    }}
  >
    {children}
  </Container>
);


// Grille responsive pour les cartes de fonctionnalités
const ResponsiveFeatureGrid = ({ children }) => (
  <Grid
    container
    spacing={4}
    sx={{
      mt: { xs: 2, md: 2},
      mb: { xs: 2, md: 2 },
      width: '100%',
      mx: 'auto',
    }}
  >
    {React.Children.map(children, (child) => (
      <Grid item
        xs={12}
        sm={6}
        md={4}
        sx={{
          mb: { xs: 3, sm: 0 }, // Ajouter une marge en bas sur mobile
          px: { xs: 2, sm: 2 }, // Ajouter du padding horizontal
        }}>
        {child}
      </Grid>
    ))}
  </Grid>
);


// Titre principal responsive
const ResponsiveHeroTitle = ({ children }) => {
  const theme = useTheme();

  return (
    <Typography
      variant="h1"
      sx={{
        fontSize: {
          xs: '2rem',    // Mobile
          sm: '2.5rem',  // Tablet
          md: '3.5rem',  // Desktop
          lg: '4rem'     // Large desktop
        },
        fontWeight: 900,
        mb: { xs: 2, sm: 3 },
        textAlign: { xs: 'left', sm: 'center' },
        background: theme.palette.mode === 'light'
          ? `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
          : `linear-gradient(45deg, ${theme.palette.primary.light}, ${theme.palette.secondary.light})`,
        backgroundClip: 'text',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        padding: { xs: '0 1rem', sm: 0 }
      }}
    >
      {children}
    </Typography>
  );
};

// Section héros responsive
const ResponsiveHeroSection = () => (
  
  <Box
    sx={{
      pt: { xs: 10, sm: 12, md: 15 },
      pb: { xs: 6, sm: 8 },
      textAlign: { xs: 'left', sm: 'center' }
    }}
  >
    <ResponsiveHeroTitle>
      Stay Ahead of Cyber Threats, <br />
      Save Hours Every Week
    </ResponsiveHeroTitle>

    <Typography
      variant="h5"
      color="text.secondary"
      sx={{
        fontSize: { xs: '1.2rem', sm: '1.5rem' },
        maxWidth: 600,
        mx: { xs: 2, sm: 'auto' },
        mb: { xs: 4, sm: 6 },
        px: { xs: 1, sm: 0 }
      }}
    >
      Join cybersecurity professionals who trust our AI-powered platform
      to deliver critical insights and save hours weekly on threat intelligence.
    </Typography>

    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      spacing={{ xs: 2, sm: 3 }}
      justifyContent={{ xs: 'flex-start', sm: 'center' }}
      sx={{ px: { xs: 2, sm: 0 } }}
    >
      <Button
        variant="contained"
        onClick={() => {
          window.location.href = 'https://auth.cybersecnews.ai/realms/cybersecnews/protocol/openid-connect/registrations?client_id=appcybersecnewsai&scope=openid%20profile&redirect_uri=https://app.cybersecnews.ai&response_type=code';
        }}
        startIcon={<Zap size={16} />}
        sx={{
          borderRadius: 2,
          py: { xs: 1.5, sm: 2 },
          px: { xs: 4, sm: 6 },
          fontSize: { xs: '1rem', sm: '1.25rem' },
          width: { xs: '100%', sm: 'auto' }
        }}
      >
        Start 5-Day Free Trial
      </Button>
    </Stack>
  </Box>
);


export const LandingPage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const handleLoginClick = () => {
    window.location.href = 'https://app.cybersecnews.ai';
  };

  const handleSignUpClick = () => {
    setLoading(true);
    window.location.href = 'https://auth.cybersecnews.ai/realms/cybersecnews/protocol/openid-connect/registrations?client_id=appcybersecnewsai&scope=openid%20profile&redirect_uri=https://app.cybersecnews.ai&response_type=code';
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f5f5f5' }}>
      <ResponsiveAppBar
        onLoginClick={handleLoginClick}
        onSignUpClick={handleSignUpClick}
      />

      <ResponsiveContainer sx={{ mb: 8 }}>
      <ResponsiveHeroSection />
      <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center" alignItems="center">
          <ResponsiveFeatureGrid>
            <FeatureCard
              icon={Bot}
              title="AI-Powered Intelligence"
              description="Our advanced AI processes the best cybersecurity sources 24/7 to deliver critical updates and identify emerging threats before they impact you."
            />
            <FeatureCard
              icon={Target}
              title="Role-Based Insights"
              description="Get intelligence customized for CISOs, Cybersecurity Experts, Security Analysts, or Incident Responders. Focus on what matters most for your role."
            />
            <FeatureCard
              icon={Clock}
              title="Hours Saved Weekly"
              description="Stop scrolling through endless articles. Our AI provides concise summaries, key takeaways, and actionable insights."
            />
          </ResponsiveFeatureGrid>
        </Grid>

        <DashboardPreview />

        <Grid container spacing={{ xs: 2, md: 4 }}>
          <Grid item xs={12} lg={10} sx={{ mx: 'auto', mt:2 }}>
            <ExampleArticle />
          </Grid>
        </Grid>

        <CreatorSection />

      </ResponsiveContainer>

      <Footer />
    </Box>
  ); 
};

export default LandingPage;