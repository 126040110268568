import React from 'react';
import { 
  Box, 
  Typography, 
  Chip, 
  IconButton,
  Link
} from '@mui/material';
import {
  ThumbsUp,
  Clock,
  Bookmark,
  Share2,
  ChevronDown
} from 'lucide-react';

const ExampleArticle = () => {
  const currentDate = new Date().toLocaleString('en-US', { 
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });

  return (
    <>
      <Typography
        variant="h3"
        align="center"
        sx={{
          mb: { xs: 2, sm: 4 },
          fontWeight: 'bold',
          fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' },
          px: { xs: 2, sm: 0 }
        }}
      >
        Stay Ahead of Critical Security Threats
      </Typography>
      
      <Box
        sx={{
          p: { xs: 2, sm: 3 },
          bgcolor: 'background.paper',
          borderRadius: { xs: 1, sm: 2 },
          boxShadow: 2,
          '&:hover': {
            boxShadow: 3,
          },
        }}
      >
        {/* Header avec point rouge et titre */}
        <Box 
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: 'space-between',
            gap: { xs: 1, sm: 2 },
            mb: 2
          }}
        >
          <Box display="flex" alignItems="center" gap={1} sx={{ width: '100%' }}>
            <Box
              sx={{
                width: 10,
                height: 10,
                borderRadius: '50%',
                bgcolor: 'error.main',
                mt: 0.8,
                flexShrink: 0
              }}
            />
            <Typography 
              variant="h6" 
              sx={{ 
                fontWeight: 500, 
                color: 'text.primary',
                fontSize: { xs: '1rem', sm: '1.25rem' },
                lineHeight: 1.4
              }}
            >
              Critical Vulnerabilities Found in Enterprise Management Systems
            </Typography>
          </Box>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{
              flexShrink: 0,
              fontSize: { xs: '0.75rem', sm: '0.875rem' }
            }}
          >
            {currentDate}
          </Typography>
        </Box>

        {/* Source et lien */}
        <Box 
          sx={{ 
            mb: 2, 
            ml: { xs: 2, sm: 3 },
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: { xs: 0.5, sm: 1 },
            alignItems: { xs: 'flex-start', sm: 'center' },
          }}
        >
          <Typography 
            variant="body2" 
            color="text.secondary" 
            component="span"
            sx={{ fontSize: { xs: '0.75rem', sm: '0.875rem' } }}
          >
            Security for geeks -
          </Typography>
          <Link 
            href="#" 
            underline="hover" 
            color="primary"
            sx={{ 
              fontSize: { xs: '0.75rem', sm: '0.875rem' },
              wordBreak: 'break-all'
            }}
          >
            https://example.com/enterprise-vulnerabilities
          </Link>
        </Box>

        {/* Résumé */}
        <Typography 
          variant="body1" 
          sx={{ 
            ml: { xs: 2, sm: 3 }, 
            mb: 2,
            fontSize: { xs: '0.875rem', sm: '1rem' }
          }}
        >
          Security researchers have discovered multiple high-severity vulnerabilities in widely-used enterprise management systems, affecting over 1,000 organizations globally.
        </Typography>

        {/* Actions et tags */}
        <Box 
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: 'space-between',
            alignItems: { xs: 'flex-start', sm: 'center' },
            gap: 2,
            mt: 2
          }}
        >
          <Box display="flex" gap={1}>
            <IconButton size="small">
              <ThumbsUp size={18} />
            </IconButton>
            <IconButton size="small">
              <Clock size={18} />
            </IconButton>
            <IconButton size="small">
              <Bookmark size={18} />
            </IconButton>
            <IconButton size="small">
              <Share2 size={18} />
            </IconButton>
          </Box>

          <Box 
            sx={{ 
              display: 'flex', 
              flexWrap: 'wrap',
              gap: 1
            }}
          >
            <Chip 
              label="CISO" 
              size="small" 
              sx={{ 
                bgcolor: 'primary.main', 
                color: 'white',
                '&:hover': { bgcolor: 'primary.dark' },
                fontSize: { xs: '0.75rem', sm: '0.875rem' }
              }} 
            />
            <Chip 
              label="Incident Response Manager" 
              size="small"
              sx={{ 
                bgcolor: 'info.main', 
                color: 'white',
                '&:hover': { bgcolor: 'info.dark' },
                fontSize: { xs: '0.75rem', sm: '0.875rem' }
              }}
            />
          </Box>
        </Box>

        {/* Main points */}
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 1, 
            mt: 3,
            fontSize: { xs: '0.875rem', sm: '1rem' },
            fontWeight: 'medium'
          }}
        >
          Main points:
        </Typography>
        <Box 
          component="ul" 
          sx={{ 
            m: 0, 
            mb: 2, 
            pl: '1.5rem',
            '& li': {
              fontSize: { xs: '0.875rem', sm: '1rem' },
              mb: 0.5
            }
          }}
        >
          <li>Multiple critical vulnerabilities identified in enterprise management software</li>
          <li>Over 1,000 organizations potentially affected worldwide</li>
          <li>Active exploitation observed in the wild</li>
          <li>Emergency patches have been released by vendors</li>
          <li>CISA has added these vulnerabilities to its Known Exploited Vulnerabilities Catalog</li>
        </Box>

        {/* Takeaways */}
        <Typography 
          variant="body1" 
          sx={{ 
            mb: 1,
            fontSize: { xs: '0.875rem', sm: '1rem' },
            fontWeight: 'medium'
          }}
        >
          Takeaways:
        </Typography>
        <Box 
          component="ul" 
          sx={{ 
            m: 0, 
            mb: 2, 
            pl: '1.5rem',
            '& li': {
              fontSize: { xs: '0.875rem', sm: '1rem' },
              mb: 0.5
            }
          }}
        >
          <li>Immediate patching is critical for all affected systems</li>
          <li>Organizations should implement network segmentation as a mitigation strategy</li>
          <li>Regular security assessments are essential to identify potential vulnerabilities</li>
        </Box>

        {/* Keywords */}
        <Box 
          sx={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: 1, 
            mt: 3 
          }}
        >
          {['Enterprise Security', 'Zero-day vulnerabilities', 'CVE-2024-0023', 'Emergency Patching'].map((keyword) => (
            <Chip 
              key={keyword}
              label={keyword} 
              size="small" 
              variant="outlined"
              sx={{
                fontSize: { xs: '0.75rem', sm: '0.875rem' }
              }}
            />
          ))}
        </Box>
      </Box>

      <Typography 
        variant="body1" 
        color="text.secondary" 
        sx={{ 
          mt: { xs: 3, sm: 4 }, 
          textAlign: 'center',
          fontSize: { xs: '0.875rem', sm: '1rem' },
          px: { xs: 2, sm: 0 }
        }}
      >
        Save hours of research with AI-curated security alerts relevant to your role
      </Typography>
    </>
  );
};

export default ExampleArticle;