// App.js
import React, { useState, useMemo } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles'; // Notez le /styles
import { CssBaseline } from '@mui/material';
import LandingPage from './LandingPage';

function App() {

  return (
      <LandingPage />
  );
}

export default App;