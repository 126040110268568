import React from 'react';
import { Box, Typography, Card, Grid, Container, useTheme, alpha } from '@mui/material';
import { TrendingDown, AlertCircle, TrendingUp, BarChart2, Hash } from 'lucide-react';

const StatCard = ({ title, value, trend, trendValue, comparison, icon: Icon, alertLevel = 'normal', keyword = false }) => {
  const theme = useTheme();

  const getAlertColor = () => {
    switch (alertLevel) {
      case 'high':
        return theme.palette.error.main;
      case 'medium':
        return theme.palette.warning.main;
      default:
        return theme.palette.success.main;
    }
  };

  const getTrendColor = () => {
    if (trend === 'down') {
      return theme.palette.error.main;
    }
    return theme.palette.primary.main;
  };

  return (
    <Card
      sx={{
        p: 1.5,
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        transition: 'all 0.3s ease-in-out',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: 2,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          transform: 'translateY(-5px)',
          borderColor: alertLevel === 'high' ? theme.palette.error.main : theme.palette.primary.main,
          boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.15)}`,
        },
        ...(alertLevel === 'high' && {
          borderColor: theme.palette.error.main,
          boxShadow: `0 0 0 1px ${theme.palette.error.main}`,
          '&:hover': {
            boxShadow: `0 8px 24px ${alpha(theme.palette.error.main, 0.15)}`,
          },
        })
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'flex-start', 
        mb: 2
      }}>
        <Typography 
          variant="h6" 
          color="text.secondary" 
          sx={{ 
            fontSize: '0.875rem',
            fontWeight: 500,
            textTransform: 'uppercase',
            letterSpacing: '0.1em'
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            p: 1,
            borderRadius: '50%',
            bgcolor: alpha(getAlertColor(), 0.1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transition: 'all 0.3s ease',
            '&:hover': {
              bgcolor: alpha(getAlertColor(), 0.2),
              transform: 'scale(1.1)',
            }
          }}
        >
          <Icon size={20} color={getAlertColor()} />
        </Box>
      </Box>
      
      
      {keyword ? (
        <Box>
          <Typography 
            variant="h3" 
            sx={{ 
              mb: 1, 
              fontWeight: 'bold',
              fontSize: '2rem',
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              color: theme.palette.text.primary
            }}
          >
            {value}
            <TrendingUp 
              size={24} 
              color={getTrendColor()}
              style={{ transition: 'transform 0.3s ease' }}
            />
          </Typography>
        </Box>
      ) : (
        <Typography 
          variant="h3" 
          sx={{ 
            mb: 1, 
            fontWeight: 'bold',
            color: theme.palette.text.primary
          }}
        >
          {value}
        </Typography>
      )}

      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        mt: 'auto'
      }}>
        {!keyword && (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: getTrendColor(),
              transition: 'all 0.3s ease',
              '&:hover': {
                transform: 'scale(1.05)',
              }
            }}
          >
            {trend === 'down' ? (
              <TrendingDown size={16} />
            ) : (
              <TrendingUp size={16} />
            )}
            <Typography 
              variant="body2" 
              sx={{ 
                ml: 0.5,
                fontWeight: 500
              }}
            >
              {trendValue}
            </Typography>
          </Box>
        )}
        <Typography 
          variant="body2" 
          color="text.secondary"
          sx={{
            fontSize: '0.875rem',
            opacity: 0.8
          }}
        >
          {comparison}
        </Typography>
      </Box>
    </Card>
  );
};

const DashboardPreview = () => {
  const theme = useTheme();

  return (
    <Container maxWidth="lg" sx={{ py: 8 }}>
      <Typography
        variant="h2"
        sx={{
          mt: 6,
          mb: 6,
          textAlign: 'center',
          fontSize: { xs: '2rem', sm: '2.5rem' },
          fontWeight: 'bold',
          color: theme.palette.text.primary
        }}
      >
        Real-time Security Intelligence Dashboard
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} sx={{mb: '0.5rem'}}>
          <StatCard
            title="VERY HIGH ARTICLES"
            value="5"
            trend="down"
            trendValue="7.69%"
            comparison="Last 24 hours"
            icon={BarChart2}
            alertLevel="high"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{mb: '0.5rem'}}>
          <StatCard
            title="TOP RISING KEYWORD"
            value="Ransomware"
            comparison="This month: 10 | Same day last month: 5"
            icon={Hash}
            alertLevel="normal"
            keyword={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            title="Articles Processed"
            value="1,247"
            trend="up"
            trendValue="22%"
            comparison="vs last month"
            icon={BarChart2}
          />
        </Grid>
      </Grid>

      <Typography 
        variant="body1" 
        color="text.secondary" 
        sx={{ 
          mt: 8, 
          textAlign: 'center',
          opacity: 0.8
        }}
      >
        Stay informed with real-time metrics and trends tailored to your security needs
      </Typography>
    </Container>
  );
};

export default DashboardPreview;