import React from 'react';
import {
  Box,
  Container,
  Typography,
  Stack,
  Link,
  Divider,
  useTheme,
  alpha
} from '@mui/material';
import { Shield, Mail, MapPin, Phone } from 'lucide-react';
import cybersecnewslogo from './Assets/images/cybersecnewslogo.png';

const Footer = () => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear();

  return (
    <Box
      component="footer"
      sx={{
        bgcolor: 'black',
        color: 'white',
        py: 4,
        borderTop: `1px solid ${alpha(theme.palette.common.white, 0.1)}`,
      }}
    >
      <Container maxWidth="lg">
        <Stack spacing={4}>
          {/* Logo et powered by section */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            alignItems="center"
            justifyContent="space-between"
            spacing={2}
          >
            <Stack 
              direction="row" 
              alignItems="center" 
              spacing={1}
              sx={{
                '& svg': {
                  color: theme.palette.primary.main
                }
              }}
            >
              {/* <Shield size={24} /> */}
              <img src={cybersecnewslogo} alt="CyberSecNews.AI" width={25} height={25} />
              <Typography 
                variant="h6" 
                component="div"
                sx={{ 
                  fontWeight: 'bold',
                  background: 'white',
                  //background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                CyberSecNews.AI
              </Typography>
            </Stack>
            
            <Typography 
              variant="body2" 
              sx={{ 
                color: alpha(theme.palette.common.white, 0.7),
                fontWeight: 500
              }}
            >
              Powered by{' '}
              <Typography
                component="span"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: 'bold'
                }}
              >
                Infosec Sécurité de l'information Inc.
              </Typography>
            </Typography>
          </Stack>

          <Divider sx={{ borderColor: alpha(theme.palette.common.white, 0.1) }} />

          {/* Copyright et informations légales */}
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ 
              color: alpha(theme.palette.common.white, 0.7),
              fontSize: '0.875rem'
            }}
          >
            <Typography variant="body2">
              © {currentYear} Infosec Sécurité de l'information Inc. All rights reserved.
            </Typography>

            <Stack 
              direction="row" 
              spacing={3}
              sx={{
                '& a': {
                  color: 'inherit',
                  textDecoration: 'none',
                  transition: 'color 0.2s',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  '&:hover': {
                    color: theme.palette.primary.main
                  }
                }
              }}
            >
              <Link href="#privacy" underline="none">Privacy Policy</Link>
              <Link href="#terms" underline="none">Terms of Service</Link>
              <Link href="#contact" underline="none">Contact</Link>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Footer;